<template>
  <MainContent>
    <CustomerTimelineModalGroup />
    <TimelineModal
      @resolve="resolveConversation($event._id)"
      @unresolve="unresolveConversation($event._id)"
      @block="blockCustomer({ companyId: $event.company, customerId: $event.customer._id })"
      @unblock="unblockCustomer({ companyId: $event.company, customerId: $event.customer._id })"
    />
    <WinnerBoardModal />
    <PastWinnersModal />
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 minimum-height">
          <CustomersHeader />
          <CustomersTableCard :limit="25" />
        </div>
      </div>
    </div>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import CustomersHeader from '@/components/Modules/Customer/CustomersHeader'
import CustomersTableCard from '@/components/Modules/Customer/CustomersTableCard/CustomersTableCard'
import CustomerTimelineModalGroup from '@/components/Modules/Customer/CustomerTimeline/CustomerTimelineModalGroup'
import WinnerBoardModal from '@/components/Modals/WinnerBoardModal/WinnerBoardModal'
import PastWinnersModal from '@/components/Modals/WinnerBoardModal/PastWinnersModal'
import TimelineModal from '@/components/Modules/WinbackV2/TimelineModal'

import { createNamespacedHelpers } from 'vuex'
const { mapMutations } = createNamespacedHelpers('customer')
const WinbackModule = createNamespacedHelpers('winback')
import { trackEvent } from '@/lib/analytics'

export default {
  name: 'CustomersPage',
  components: {
    CustomersHeader,
    CustomersTableCard,
    MainContent,
    WinnerBoardModal,
    PastWinnersModal,
    CustomerTimelineModalGroup,
    TimelineModal,
  },
  methods: {
    ...mapMutations(['setSkipCount', 'resetListFilters']),
    ...WinbackModule.mapActions([
      'blockCustomer',
      'unblockCustomer',
      'resolveConversation',
      'unresolveConversation',
    ]),
  },
  mounted() {
    trackEvent(this.$intercom, 'Customers')
  },
  beforeMount() {
    this.setSkipCount(0)
    this.resetListFilters()
  },
}
</script>

<style scoped lang="scss">
.minimum-height {
  min-height: 650px;
}
</style>
