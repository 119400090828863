<template>
  <div class="header">
    <div class="header-body">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="header-pretitle">Overview</h6>
          <h1 class="header-title">Customers</h1>
        </div>
        <div class="col-auto">
          <div class="d-inline-block mr-3 mb-1" v-if="selectIsPermitted('customerSelectWinner')">
            <button
              aria-expanded="false"
              aria-haspopup="true"
              class="btn btn-outline-primary"
              data-toggle="dropdown"
              id="dropdownWinnerMenuButton"
            >
              + 🎉
            </button>
            <ul aria-labelledby="dropdownWinnerMenuButton" class="dropdown-menu">
              <li>
                <a href="javascript:;" class="dropdown-item" v-b-modal.winnerBoardModal
                  >Choose a winner</a
                >
              </li>
              <li>
                <a href="#" class="dropdown-item" v-b-modal.pastWinnersModal>View past winners</a>
              </li>
            </ul>
          </div>
          <div
            class="dropdown d-inline-block mr-3 mb-1"
            v-if="selectIsPermitted('customerCreation')"
          >
            <button
              aria-expanded="false"
              aria-haspopup="true"
              class="btn btn-outline-primary"
              data-toggle="dropdown"
              id="dropdownAddMenuButton"
              type="button"
            >
              + Add
            </button>
            <ul aria-labelledby="dropdownAddMenuButton" class="dropdown-menu">
              <li>
                <router-link :to="{ name: 'customers-add' }" class="dropdown-item"
                  >Single Entry</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'customers-import' }" class="dropdown-item"
                  >Bulk Import</router-link
                >
              </li>
            </ul>
          </div>
          <div class="d-inline-block mr-3 mb-1" v-if="selectIsPermitted('customerExport')">
            <button
              aria-expanded="false"
              aria-haspopup="true"
              class="btn btn-outline-primary"
              @click="verifyExport()"
              id="ExportMenuButton"
            >
              <span class="fas fa-spinner fa-spin text-muted" v-if="exportLoading"></span>
              <span class="fe fe-download" v-else></span> Export
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const userModule = createNamespacedHelpers('user')
const companyModule = createNamespacedHelpers('company')
const CustomerModule = createNamespacedHelpers('customer')

const WS_EVENT_TYPES = {
  CustomersExportRequested: 'customers-export-requested',
  CustomersExported: 'customers-exported',
  CustomersExportFailed: 'customers-export-failed',
}

// @group Customers
// This Component is the main header sectiion for the customers page
export default {
  name: 'CustomersHeader',

  computed: {
    ...userModule.mapState(['onlineUser']),
    ...companyModule.mapState(['activeCompany']),
    ...companyModule.mapGetters(['selectIsPermitted']),
    ...CustomerModule.mapState(['customers', 'count']),
  },

  data: () => ({
    exportLoading: false,
  }),

  mounted() {
    this.pushWSMessageHandler({ key: 'customers-export', handler: this.onWSMessage })
  },

  methods: {
    ...userModule.mapActions(['pushWSMessageHandler']),
    ...CustomerModule.mapActions(['getFilteredCSV']),

    // @vuese
    // Downloads the pre-generated filtered CSV returned from the server
    async exportCustomerList() {
      try {
        this.exportLoading = true

        const h = this.$createElement
        const titleVNode = h('div', [
          h('h2', { class: ['mb-0'], domProps: { innerHTML: 'Your export has been initiated' } }),
        ])

        const email = this.onlineUser.email || ''

        const messageVNode = h('p', {
          domProps: {
            innerHTML: `This could take a couple of minutes. 
        If you need to leave our site, we'll email the export to <strong>${email}</strong>`,
          },
        })

        const okayMessageBox = await this.$bvModal.msgBoxOk([messageVNode], {
          title: [titleVNode],
          size: 'md',
          okTitle: 'Okay',
          centered: true,
        })

        if (okayMessageBox) {
          await this.getFilteredCSV()
        }

        this.exportLoading = false
      } catch (error) {
        this.$notify({
          type: 'error',
          duration: 5000,
          title: `Exporting customers failed! Try again`,
        })
      }
    },

    // @vuese
    // Displays modal verifying the user wants to export with the current filters.
    async verifyExport() {
      const message = `
        A CSV file will be created with the filters 
        that are currently applied to the Customers table
        `
      const h = this.$createElement
      const titleVNode = h('div', [
        h('h2', { class: ['mb-0'], domProps: { innerHTML: 'Confirm Export' } }),
      ])
      let exportConfirmed = await this.$bvModal.msgBoxConfirm(message, {
        title: [titleVNode],
        size: 'md',
        okTitle: 'Export',
        cancelTitle: 'Cancel',
        centered: true,
      })
      if (exportConfirmed) this.exportCustomerList()
    },

    onWSMessage(message) {
      const { type, detail } = message

      if (this.onlineUser._id !== detail.userId && this.activeCompany._id !== detail.companyId) {
        console.log('WS unmatched client', type)
        return
      }

      console.log('WS message:', { type, detail })

      switch (type) {
        case WS_EVENT_TYPES.CustomersExported:
          this.$notify({
            title: 'Export completed',
            duration: 30000,
            ignoreDuplicates: true,
            text: `You CSV file is ready`,
          })
          const anchor = document.createElement('a')
          anchor.href = detail.csvUrl
          anchor.setAttribute('download', '')
          anchor.click()
          break
        case WS_EVENT_TYPES.CustomersExportFailed:
          this.$notify({
            type: 'error',
            title: `Exporting customers failed! Try again`,
          })
          break
        default:
          console.log('WS unhandle message:', type)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
