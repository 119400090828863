<template>
  <div>
    <FadeTransition>
      <div v-if="winners.length" class="card-body p-0">
        <div class="table-responsive mb-0">
          <table id="winner-board-table" class="table table-sm table-nowrap card-table">
            <thead>
              <tr>
                <th>
                  <a class="text-muted" href="javascript:;">Past Winners</a>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody class="list">
              <tr v-for="winner of winners" :key="winner._id">
                <td>{{ winner.name }}</td>
                <td>{{ winner.phone | phone }}</td>
                <td class="text-right text-muted">
                  {{ winner.timestamp | selectedOn }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else class="card-body text-center p-5">There are no past winners</div>
    </FadeTransition>
  </div>
</template>

<script>
import FadeTransition from '@/components/Transitions/FadeTransition'
import { format as formatDate } from 'date-fns'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('customer')

export default {
  name: 'WinnerBoardPastWinners',
  props: ['showImmediately'],
  components: {
    FadeTransition,
  },
  data: () => ({
    winners: [],
  }),
  filters: {
    selectedOn(date) {
      return formatDate(date, 'MM/DD/YY')
    },
  },
  methods: {
    ...mapActions(['getWinners']),
    async displayWinners() {
      const winners = await this.getWinners()
      const timeout = this.showImmediately ? 0 : 2000
      setTimeout(() => (this.winners = winners), timeout)
    },
  },
  mounted() {
    this.displayWinners()
  },
}
</script>

<style scoped lang="scss"></style>
