<template>
  <b-modal ref="modal" id="pastWinnersModal" hide-footer hide-header body-class="p-0">
    <div class="modal-card card" style="border: none">
      <WinnerBoardHeader />
      <WinnerBoardPastWinners show-immediately="true" />
    </div>
  </b-modal>
</template>

<script>
import WinnerBoardHeader from './WinnerBoardHeader'
import WinnerBoardPastWinners from './WinnerBoardPastWinners'

export default {
  name: 'PastWinnersModal',
  components: {
    WinnerBoardHeader,
    WinnerBoardPastWinners,
  },
}
</script>
