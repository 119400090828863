<template>
  <div>
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h4 class="card-header-title" id="exampleModalCenterTitle">Winner Board</h4>
        </div>
        <div class="col-auto">
          <button @click="close" type="button" class="close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
    </div>
    <div class="card-header p-0">
      <div class="winner-section text-center">
        <img src="/img/illustrations/win.png" alt="..." class="img-fluid" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WinnerBoardHeader',
  methods: {
    close() {
      this.$root.$emit('bv::hide::modal', 'winnerBoardModal')
      this.$root.$emit('bv::hide::modal', 'pastWinnersModal')
    },
  },
}
</script>

<style scoped lang="scss"></style>
