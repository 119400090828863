<template>
  <b-modal ref="modal" id="winnerBoardModal" hide-footer hide-header body-class="p-0">
    <div class="modal-card card" style="border: none; min-height: 600px">
      <WinnerBoardHeader />
      <WinnerBoardWinner v-if="initialized" />
      <WinnerBoardPastWinners v-if="initialized" />
    </div>
  </b-modal>
</template>

<script>
import WinnerBoardHeader from './WinnerBoardHeader'
import WinnerBoardWinner from './WinnerBoardWinner'
import WinnerBoardPastWinners from './WinnerBoardPastWinners'

export default {
  name: 'WinnerBoardMoal',
  components: {
    WinnerBoardHeader,
    WinnerBoardWinner,
    WinnerBoardPastWinners,
  },
  data: () => ({ initialized: false }),
  methods: {
    handleModalOpen(event, modalId) {
      if (modalId === 'winnerBoardModal') this.initialized = true
    },
    handleModalClose(event, modalId) {
      if (modalId === 'winnerBoardModal') this.initialized = false
    },
  },
  mounted() {
    this.$root.$on('bv::modal::shown', this.handleModalOpen)
    this.$root.$on('bv::modal::hidden', this.handleModalClose)
  },
  destroyed() {
    this.$root.$off('bv::modal::shown', this.handleModalOpen)
    this.$root.$off('bv::modal::hidden', this.handleModalClose)
  },
}
</script>
