<template>
  <div class="card-body winner">
    <canvas id="confetti"></canvas>
    <div class="winner-details">
      <div class="details-wrapper">
        <p class="preview">And the winner is...</p>
        <BounceTransition>
          <h1 v-if="winner && winner.name">{{ winner.name }}</h1>
        </BounceTransition>
        <FadeTransition>
          <p v-if="winner && winner.phone" class="text-muted mb-0">{{ winner.phone | phone }}</p>
        </FadeTransition>
        <div v-if="winner" class="px-3 mb-4 mt-4">
          <p class="text-muted mb-0">
            We have not notified the customer. Go ahead and reach out to them to let them know they
            have won!
          </p>
        </div>
        <div v-if="!winner">
          <h1>&nbsp;</h1>
          <p class="mb-0">&nbsp;</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'confetti-js'
import FadeTransition from '@/components/Transitions/FadeTransition'
import BounceTransition from '@/components/Transitions/BounceTransition'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('customer')

export default {
  name: 'WinnerBoardWinner',
  components: {
    FadeTransition,
    BounceTransition,
  },
  data: () => ({
    winner: undefined,
  }),
  methods: {
    ...mapActions(['chooseWinner']),
    showConfetti() {
      const confettiSettings = { target: 'confetti', height: 200, width: 2000 }
      const confetti = new ConfettiGenerator(confettiSettings)
      confetti.render()
    },
    displayWinner() {
      setTimeout(async () => {
        try {
          const winner = await this.chooseWinner()
          this.winner = winner
        } catch (err) {
          this.$notify({
            title: 'Winner issue.',
            text: err.body.message,
            type: 'error',
          })
        }
      }, 1000)
    },
  },
  mounted() {
    this.showConfetti()
    this.displayWinner()
  },
}
</script>

<style scoped lang="scss">
.winner {
  padding: 0px;
  height: 135px;
  position: relative;
  overflow: hidden;

  .winner-details {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;

    .details-wrapper {
      background: rgba(#fff, 0.9);
      border-radius: 10px;
      box-shadow: 0px 0px 16px 10px rgba(#fff, 0.8);
    }
  }
}
</style>
